export const Main = "https://younoplus.com";
//export const Main = "http://localhost:8000";
export const Api = {
  LoginApi: `${Main}/api/admin/admin-login`,
  DashboardApi: `${Main}/api/admin/admin-dash`,
  ShowUsersApi: `${Main}/api/admin/show-users`,
  AddUserCSV: `${Main}/api/admin/user-csv`,
  AddDriverCSV: `${Main}/api/admin/driver-csv`,
  AddBookingCSV: `${Main}/api/admin/booking-csv`,
  EditUser: `${Main}/api/admin/update-user`,
  DeleteUser: `${Main}/api/admin/remove-user`,
  ShowDriver: `${Main}/api/admin/show-driver`,
  DriverStatus: `${Main}/api/admin/approve-driver`,
  AddDriver: `${Main}/api/admin/add-driver`,
  RemoveDriver: `${Main}/api/admin/remove-driver`,
  UpdateDriver: `${Main}/api/admin/update-driver`,

  AddBooking: `${Main}/api/booking/booking`,
  ShowBooking: `${Main}/api/admin/show-booking`,
  RemoveBooking: `${Main}/api/admin/remove-booking`,
  EditBooking: `${Main}/api/admin/edit-booking`,

  ShowDriverDoc: `${Main}/api/admin/show-driverdoc`,
  VerifyDriver: `${Main}/api/admin/verify-driverdoc`,
  ShowBanner: `${Main}/api/admin/show-banner`,
  ChangeBannerStatus: `${Main}/api/admin/change-banner-status`,
  AddBanner: `${Main}/api/admin/add-banner`,
  DeleteBanner: `${Main}/api/admin/remove-banner`,
  UpdateBanner: `${Main}/api/admin/update-banner`,
  ShowNotification: `${Main}/api/admin/show-notification`,
  AddNotification: `${Main}/api/admin/add-notification`,
  RemoveNotification: `${Main}/api/admin/remove-notification`,
  AddFAQ: `${Main}/api/admin/add-faq`,
  ShowFAQ: `${Main}/api/admin/show-faq`,
  RemoveFAQ: `${Main}/api/admin/remove-faq`,
  UpdateFAQ: `${Main}/api/admin/update-faq`,
  ShowDispute: `${Main}/api/admin/show-dispute-support`,
  DeleteDispute: `${Main}/api/admin/remove-dispute-support`,
  ShowPayment: `${Main}/api/admin/show-payment-support`,
  DeletePayment: `${Main}/api/admin/remove-payment-support`,
  ShowOther: `${Main}/api/admin/show-other-support`,
  DeleteOther: `${Main}/api/admin/remove-other-support`,
  ShowContent: `${Main}/api/admin/show-content`,
  UpdateContent: `${Main}/api/admin/update-content`,
  AdminShow: `${Main}/api/admin/admin-show`,
  AdminProfileUpdate: `${Main}/api/admin/admin-profile`,
  AdminUpdate: `${Main}/api/admin/admin-update`,
  AdminChangePassword: `${Main}/api/admin/admin-change-password`,
  AdminForgotPassword: `${Main}/api/admin/admin-forgot-password`,
  AdminEmailLink: `${Main}/api/admin/admin-link`,
  ShowFilterWiseBook: `${Main}/api/admin/show-book-filterwise`,
  ShowBide: `${Main}/api/admin/show-bird`,
  ShowRevenue: `${Main}/api/admin/show-revenue`,
  AddNewDispatchRequest: `${Main}/api/admin/add-dispatch`,
  ShowNewRequests: `${Main}/api/admin/new-dispatch-requests`,
  EditNewRequests: `${Main}/api/admin/edit-dispatch-requests`,
  AddRequestCSV: `${Main}/api/admin/csv-dispatch-requests`,

  // Vehicle
  AddVehicle: `${Main}/api/admin/add-vehicles`,
  ShowVehicle: `${Main}/api/admin/show-vehicles`,
  UpdateVehicle: `${Main}/api/admin/update-vehicles`,
  DeleteVehicle: `${Main}/api/admin/delete-vehicles`,

  // PromoCode
  AddPromoCode: `${Main}/api/admin/add-promocode`,
  ShowPromoCode: `${Main}/api/admin/show-promocode`,
  ChangePromoCode: `${Main}/api/admin/change-promocode`,
  UpdatePromoCode: `${Main}/api/admin/update-promocode`,
  DeletePromoCode: `${Main}/api/admin/delete-promocode`,
};
